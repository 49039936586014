import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#85273e' }}>
      <div className="container-fluid pt-5 pb-4">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-3 col-md-12 mb-4 d-flex justify-content-center">
              <img style={{ maxHeight: '160px' }} src="https://res.cloudinary.com/dm0hew9tr/image/upload/v1726770952/igaming/ig003-Photoroom_to0wdw.png" alt="" />
            </div>
            <div className="col-lg-3 col-md-12 mb-4">
              <div className="footer-widget">
                <h5 className="mb-4 text-warning font-secondary">Quick Links</h5>
                <ul className="list-unstyled text-white">
                  <li className="mb-2"><a href="#"><span className="text-light">Play</span></a></li>
                  <li className="mb-2"><a href="#"><span className="text-light">Events</span></a></li>
                  <li className="mb-2"><a href="#"><span className="text-light">About Us</span></a></li>
                  <li className="mb-2"><a href="#"><span className="text-light">Contact Us</span></a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 mb-5">
              <h5 className="mb-4 text-warning">Get In Touch</h5>
              <p className="font-weight-medium text-white"><i className="fa fa-map-marker-alt text-warning px-2"></i>Nairobi, Kenya</p>
              <p className="font-weight-medium text-white"><i className="fa fa-phone-alt text-warning px-2"></i>+254 756 222 200</p>
              <p className="font-weight-medium text-white"><i className="fa fa-envelope text-warning px-2"></i>info@igamingexpo.africa</p>
            </div>
            <div className="col-lg-3 col-md-12 newsletter-form">
              <div style={{ paddingTop: '35px' }}>
                <h5 className="mb-4 text-warning font-secondary">Subscribe</h5>
                <div className="pe-0">
                  <form method="post" role="form" id="subscribe_form" name="mc-embedded-subscribe-form">
                    <div className="input-group mb-3">
                      <input type="email" name="subscribe_email" id="subscribe_email" className="form-control shadow-none bg-white border-end-0" placeholder="Email address" required />
                      <span className="input-group-text border-0 p-0">
                        <button className="input-group-text border-0 bg-warning" type="submit" name="subscribe" aria-label="Subscribe for Newsletter"><i className="fas fa-arrow-right"></i></button>
                      </span>
                    </div>
                    <div id="mc_embed_signup" className="d-none"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-center">
        <p className="text-white mb-0 py-3">© 2024 iGaming Expo Africa | All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
