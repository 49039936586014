import React from 'react';

const Header = () => {
  return (
    <header className="navigation" style={{ background: 'linear-gradient(to right, #85273e, #b22a65)' }}>
      <nav className="navbar navbar-expand-xl navbar-light text-center text-light py-3">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img loading="preload" decoding="async" className="img-fluid" width="140" height="70" src="https://res.cloudinary.com/dm0hew9tr/image/upload/v1726766687/igaming/logo_kgtay3.png" alt="iGaming-Expo-Africa" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0 text-light">
              <li className="nav-item">
                <a className="nav-link text-light" href="#">About</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#">Exhibit</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#">Floorplan</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#">Agenda</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#">Speakers</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#">Sponsors</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#">Pitch</a>
              </li>
              <li className="nav-item">
                <a className="nav-link text-light" href="#">Plan Your Trip</a>
              </li>
            </ul>
            {/* <a href="#" className="btn btn-danger my-2 mx-2"><i className="fa fa-users px-2 "></i>Get in touch</a> */}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
